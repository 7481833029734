import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedProductComponent } from './components/featured-product/featured-product.component';
import { LayoutModule } from '../../shared/layout/layout.module';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';



@NgModule({
  declarations: [FeaturedProductComponent, ProductDetailComponent, GiftCardComponent],
  imports: [
    CommonModule,
    LayoutModule
  ],
  exports: [
    FeaturedProductComponent,
    ProductDetailComponent,
    GiftCardComponent
  ]
})
export class ProductModule { }
