import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet'
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
@Component({
  selector: 'bared-search-bottom-sheet',
  templateUrl: './search-bottom-sheet.component.html'
})
export class SearchBottomSheetComponent implements OnInit {
  public searchForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
              private _bottomSheetRef: MatBottomSheetRef<SearchBottomSheetComponent>) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      searchControl: new FormControl()
    });
  }
  navigateSearch() {
    const value = this.searchForm.get('searchControl').value;
    this._bottomSheetRef.dismiss(value);
  }
}
