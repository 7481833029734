import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SideBars } from '../../models/layout.constants';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'bared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() navigate = new EventEmitter<string[]>();
  @Output() openSidebar = new EventEmitter<SideBars>();
  @Input() wishlistItemCount = 1;
  @Input() cartItemCount = 1;
  
  form = new FormGroup({
    
  });
  myControl = new FormControl();
  constructor() { }

  ngOnInit() {
  }

  search(query: string) {
    // Navigate to search/$Query
    this.navigate.emit(['search', query ] );
  }
  openWishlist() {
    // Not sure if we need a string - could be a routeOption?
    this.navigate.emit(['Wishlist'])
  }
  openCart() {
    console.log('clicks');
    // Should have an option to close from elsewhere - even though likely not used.
    this.openSidebar.emit(SideBars.ShoppingCart);
  }
}
