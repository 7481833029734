import { Component, OnInit, Input } from '@angular/core';
import { Product, ProductOptions } from '../../models/product';

@Component({
  selector: 'bared-featured-product',
  templateUrl: './featured-product.component.html',
  styleUrls: ['./featured-product.component.scss']
})
export class FeaturedProductComponent implements OnInit {

  @Input() product: Product<ProductOptions>;
  public currency = {
    rate: 1,
    symbol: 'AUD',
    value: 'AUD'
  };
  constructor() { }

  ngOnInit() {
  }

}
