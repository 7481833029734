import { ExtendedTile, ButtonTile } from '../models/tile-model';


export const featuredTilesResult = {
    "result": [
      {
        "id": "645",
        "type": "1",
        "sort": "10",
        "image": "1569281248641_Untitled design.jpg",
        "button_label": 'Review to win',
        "button_visible": true,
        "caption": "Click here for more info",
        "url": "https:\/\/bared.com.au\/share-bared-competition",
        "new_window": false,
        "status": "1",
        "created_on": "2019-09-10 17:42:46"
      },
      {
        "id": "461",
        "type": "1",
        "sort": "9",
        "image": "1549852345815_Footbed---Square2.jpg",
        "button_label": null,
        "button_visible": false,
        "caption": "Click here to learn more",
        "url": "https:\/\/bared.com.au\/our-difference",
        "new_window": false,
        "status": "1",
        "created_on": "2019-02-11 13:32:41"
      },
      {
        "id": "647",
        "type": "1",
        "sort": "8",
        "image": "1568338983376_Untitled design.jpg",
        "button_label": "SHOP SANDALS",
        "button_visible": true,
        "caption": "CLICK HERE TO SHOP NOW",
        "url": "https:\/\/bared.com.au\/category\/womens-sandals",
        "new_window": false,
        "status": "1",
        "created_on": "2019-09-13 11:43:03"
      },
      {
        "id": "646",
        "type": "1",
        "sort": "7",
        "image": "1568338764152_Untitled design copy.jpg",
        "button_label": "SHOP BOOTS",
        "button_visible": true,
        "caption": "click here to shop now",
        "url": "https:\/\/bared.com.au\/category\/womens-boots",
        "new_window": false,
        "status": "1",
        "created_on": "2019-09-13 11:39:24"
      },
      {
        "id": "578",
        "type": "1",
        "sort": "6",
        "image": "1562813843081_Bared_Footwear_Lauren_June-20.jpg",
        "button_label": "SHOP HEELS",
        "button_visible": true,
        "caption": "CLICK HERE TO SHOP NOW",
        "url": "https:\/\/bared.com.au\/category\/womens-heels",
        "new_window": false,
        "status": "1",
        "created_on": "2019-07-11 12:57:27"
      },
      {
        "id": "642",
        "type": "1",
        "sort": "5",
        "image": "1567492742352_tiles sep193.jpg",
        "button_label": "SHOP FLATS",
        "button_visible": true,
        "caption": "CLICK HERE TO SHOP NOW",
        "url": "https:\/\/bared.com.au\/category\/womens-flats",
        "new_window": false,
        "status": "1",
        "created_on": "2019-09-03 16:39:02"
      },
      {
        "id": "581",
        "type": "1",
        "sort": "4",
        "image": "1562890003516_Untitled design.jpg",
        "button_label": "SHOP BOOTS",
        "button_visible": true,
        "caption": "CLICK HERE TO SHOP NOW",
        "url": "https:\/\/bared.com.au\/category\/womens-boots",
        "new_window": false,
        "status": "1",
        "created_on": "2019-07-12 10:06:44"
      },
      {
        "id": "635",
        "type": "1",
        "sort": "3",
        "image": "1566539656780_Tile_Web.gif",
        "button_label": "New Blog Post",
        "button_visible": true,
        "caption": "Click here to read more",
        "url": "https:\/\/blog.bared.com.au\/2019\/08\/23\/bared-footwear-mens-store-launch\/",
        "new_window": false,
        "status": "1",
        "created_on": "2019-08-23 15:54:17"
      },
      {
        "id": "650",
        "type": "1",
        "sort": "1",
        "image": "1568339958681_Untitled design.jpg",
        "button_label": "SHOP SNEAKERS",
        "button_visible": true,
        "caption": "CLICK HERE TO SHOP NOW",
        "url": "https:\/\/bared.com.au\/category\/womens-sneakers",
        "new_window": false,
        "status": "1",
        "created_on": "2019-09-13 11:59:19"
      }
    ]
  }
  export const featuredTiles: ButtonTile[] = featuredTilesResult.result;