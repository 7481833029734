import { IBaredMenuItem } from '../models/bared-menu';

export const menuServerResult = {
    "result": [
      {
        "Category": {
          "id": 18,
          "menu_id": 1,
          "parent_id": null,
          "sort": 1,
          "label": "Women's",
          "url": {
            "type": null,
            "value": null
          },
          "status": 0,
          "created_on": "2016-11-08 11:43:16",
          "hide_main_menu": false
        },
        "hide": false,
        "Children": [
          {
            "Category": {
              "id": 107,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 2,
              "label": "All Women's",
              "url": {
                "type": "static",
                "value": "\/all\/women"
              },
              "status": -1,
              "created_on": "0000-00-00 00:00:00",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 26,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 3,
              "label": "New Arrivals",
              "url": {
                "type": "category",
                "value": 9
              },
              "status": 0,
              "created_on": "2016-11-08 13:39:23",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 74,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 4,
              "label": "Back In Stock",
              "url": {
                "type": "category",
                "value": 34
              },
              "status": 0,
              "created_on": "2017-03-22 13:57:42",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 60,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 5,
              "label": "Ballet Flats",
              "url": {
                "type": "category",
                "value": 21
              },
              "status": 0,
              "created_on": "2017-02-07 09:28:01",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 27,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 6,
              "label": "Flats",
              "url": {
                "type": "category",
                "value": 10
              },
              "status": 0,
              "created_on": "2016-11-08 13:39:48",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 29,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 7,
              "label": "Sandals",
              "url": {
                "type": "category",
                "value": 13
              },
              "status": 0,
              "created_on": "2016-11-08 13:42:12",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 28,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 8,
              "label": "Heels",
              "url": {
                "type": "category",
                "value": 12
              },
              "status": 0,
              "created_on": "2016-11-08 13:41:07",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 57,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 9,
              "label": "Boots",
              "url": {
                "type": "category",
                "value": 11
              },
              "status": 0,
              "created_on": "2017-01-17 10:24:53",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 30,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 10,
              "label": "Casual",
              "url": {
                "type": "category",
                "value": 14
              },
              "status": 0,
              "created_on": "2016-11-08 13:42:33",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 31,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 11,
              "label": "Last Pairs\/Sale",
              "url": {
                "type": "category",
                "value": 15
              },
              "status": 0,
              "created_on": "2016-11-08 13:42:50",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 90,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 12,
              "label": "Gift Vouchers",
              "url": {
                "type": "static",
                "value": "https:\/\/bared.com.au\/giftcards"
              },
              "status": 0,
              "created_on": "2017-12-21 16:29:43",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 96,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 13,
              "label": "Shoe Care",
              "url": {
                "type": "category",
                "value": 41
              },
              "status": 0,
              "created_on": "2018-03-28 16:55:30",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 102,
              "menu_id": 1,
              "parent_id": 18,
              "sort": 14,
              "label": "Accessories",
              "url": {
                "type": null,
                "value": 43
              },
              "status": 0,
              "created_on": "2018-10-04 15:52:27",
              "hide_main_menu": null
            },
            "hide": false
          }
        ]
      },
      {
        "Category": {
          "id": 19,
          "menu_id": 1,
          "parent_id": null,
          "sort": 15,
          "label": "Men's",
          "url": {
            "type": null,
            "value": null
          },
          "status": 0,
          "created_on": "2016-11-08 11:43:25",
          "hide_main_menu": false
        },
        "hide": false,
        "Children": [
          {
            "Category": {
              "id": 108,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 16,
              "label": "All Men's",
              "url": {
                "type": "static",
                "value": "\/all\/men"
              },
              "status": -1,
              "created_on": "0000-00-00 00:00:00",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 32,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 17,
              "label": "New Arrivals",
              "url": {
                "type": "category",
                "value": 16
              },
              "status": 0,
              "created_on": "2016-11-08 13:59:26",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 75,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 18,
              "label": "Back In Stock",
              "url": {
                "type": "category",
                "value": 35
              },
              "status": 0,
              "created_on": "2017-04-13 17:01:27",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 34,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 19,
              "label": "Dress",
              "url": {
                "type": "category",
                "value": 20
              },
              "status": 0,
              "created_on": "2016-11-08 13:59:57",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 65,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 20,
              "label": "Derby Shoes",
              "url": {
                "type": "category",
                "value": 26
              },
              "status": 0,
              "created_on": "2017-02-10 10:01:40",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 63,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 21,
              "label": "Loafers",
              "url": {
                "type": "category",
                "value": 24
              },
              "status": 0,
              "created_on": "2017-02-10 10:00:34",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 64,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 22,
              "label": "Boat Shoes",
              "url": {
                "type": "category",
                "value": 27
              },
              "status": 0,
              "created_on": "2017-02-10 10:00:49",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 33,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 23,
              "label": "Boots",
              "url": {
                "type": "category",
                "value": 17
              },
              "status": 0,
              "created_on": "2016-11-08 13:59:42",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 66,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 24,
              "label": "Sneakers",
              "url": {
                "type": "category",
                "value": 25
              },
              "status": 0,
              "created_on": "2017-02-10 10:02:48",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 36,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 25,
              "label": "Last Pairs\/Sale",
              "url": {
                "type": "category",
                "value": 19
              },
              "status": 0,
              "created_on": "2016-11-08 14:00:31",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 91,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 26,
              "label": "Gift Vouchers",
              "url": {
                "type": "static",
                "value": "https:\/\/bared.com.au\/giftcards"
              },
              "status": 0,
              "created_on": "2017-12-21 16:30:11",
              "hide_main_menu": false
            },
            "hide": false
          },
          {
            "Category": {
              "id": 97,
              "menu_id": 1,
              "parent_id": 19,
              "sort": 27,
              "label": "Shoe Care",
              "url": {
                "type": "category",
                "value": 41
              },
              "status": 0,
              "created_on": "2018-03-28 16:56:08",
              "hide_main_menu": false
            },
            "hide": false
          }
        ]
      },
      {
        "Category": {
          "id": 20,
          "menu_id": 1,
          "parent_id": null,
          "sort": 28,
          "label": "Our Difference",
          "url": {
            "type": null,
            "value": null
          },
          "status": 0,
          "created_on": "2016-11-08 11:43:32",
          "hide_main_menu": false
        },
        "hide": false,
        "Children": [
          {
            "Category": {
              "id": 37,
              "menu_id": 1,
              "parent_id": 20,
              "sort": 29,
              "label": "The Bared Difference",
              "url": {
                "type": "static",
                "value": "https:\/\/bared.com.au\/our-difference"
              },
              "status": 0,
              "created_on": "2016-11-08 14:01:18",
              "hide_main_menu": false
            },
            "hide": false
          }
        ]
      },
      {
        "Category": {
          "id": 21,
          "menu_id": 1,
          "parent_id": null,
          "sort": 30,
          "label": "Bared Life",
          "url": {
            "type": null,
            "value": null
          },
          "status": 0,
          "created_on": "2016-11-08 11:43:40",
          "hide_main_menu": false
        },
        "hide": false,
        "Children": [
          {
            "Category": {
              "id": 39,
              "menu_id": 1,
              "parent_id": 21,
              "sort": 31,
              "label": "Bared Blog",
              "url": {
                "type": "static",
                "value": "\/\/blog.bared.com.au\/"
              },
              "status": 0,
              "created_on": "2016-11-08 14:01:57",
              "hide_main_menu": false
            },
            "hide": false
          }
        ]
      },
      {
        "Category": {
          "id": 22,
          "menu_id": 1,
          "parent_id": null,
          "sort": 32,
          "label": "Shop Instagram",
          "url": {
            "type": "static",
            "value": "\/\/bared.com.au\/instagram"
          },
          "status": 0,
          "created_on": "2016-11-08 11:43:44",
          "hide_main_menu": false
        },
        "hide": false
      },
      {
        "Category": {
          "id": 78,
          "menu_id": 1,
          "parent_id": null,
          "sort": 33,
          "label": "Contact Us",
          "url": {
            "type": "page",
            "value": 14
          },
          "status": 0,
          "created_on": "2017-06-24 11:22:12",
          "hide_main_menu": false
        },
        "hide": false
      }
    ]
  };
export const mockedMenu: IBaredMenuItem[] = menuServerResult.result;