import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IBaredMenuItem, ICategory } from '../../layout/models/bared-menu';

@Injectable({
  providedIn: 'root'
})
export class ContentNavigationService {

  private pageIdSubscription: BehaviorSubject<number>;
  private pageId: number;
  // Page Types from config  
  private readonly categoryPrefix = "Category";
  private readonly pagePrefix = "content";
  // metadata would be a bonus
  constructor(private router: Router) {
    this.pageIdSubscription = new BehaviorSubject<number>(0);
  }
  public navigateTo(segments: string[]){
    this.router.navigate(segments);
  }
  public navigate(item: ICategory){
    this.pageId = item.id;
    this.pageIdSubscription.next(this.pageId);
    switch (item.url.type) {
      case 'category':
          this.navigateTo( [this.categoryPrefix, item.url.value.toString()]);
          break;
      case 'page':
          this.navigateTo([this.pagePrefix, item.label.replace(/\s/g,'-').toLowerCase()]);
          break;
      case 'static':
          this.navigateTo( [item.url.value.toString()]);
          break;
      case 'product': 

      }
  }
  
}
