import { ExtendedTile } from '../models/tile-model';

export const bannerResponse = {
    "result": [
      {
        "id": "625",
        "type": "1",
        "sort": "4",
        "image": "1568347717931_banner---NA09134.jpg",
        "caption": null,
        "url": "https:\/\/bared.com.au\/category\/womens-sandals",
        "status": "1",
        "created_on": "2019-09-13 14:08:38"
      },
      {
        "id": "626",
        "type": "1",
        "sort": "3",
        "image": "1568353029033_banner---NA0913.jpg",
        "caption": null,
        "url": "https:\/\/bared.com.au\/category\/womens-new-arrivals",
        "status": "1",
        "created_on": "2019-09-13 15:37:10"
      },
      {
        "id": "627",
        "type": "1",
        "sort": "2",
        "image": "1568353034220_banner---NA09132.jpg",
        "caption": null,
        "url": "https:\/\/bared.com.au\/category\/womens-new-arrivals",
        "status": "1",
        "created_on": "2019-09-13 15:37:14"
      },
      {
        "id": "623",
        "type": "1",
        "sort": "1",
        "image": "1568347301079_banner---NA09133.jpg",
        "caption": null,
        "url": "https:\/\/bared.com.au\/category\/womens-sandals",
        "status": "1",
        "created_on": "2019-09-13 14:01:41"
      }
    ]
  };
  export const banner: ExtendedTile[] = bannerResponse.result;