import { Injectable } from '@angular/core';
import { Tile, ExtendedTile, ButtonTile } from '../models/tile-model';
import { homeTiles } from '../mocks/home-page.mock';
import { ProductOptions, Product } from 'src/app/bared-shop/product/models/product';
import { featuredWomens } from '../mocks/featured-products';
import { InstagramProduct } from 'src/app/bared-shop/product/models/instagram-product';
import { instagramTiles } from '../mocks/instagram-tiles';
import { featuredTiles } from '../mocks/featured-tiles';
import { banner } from '../mocks/mock-banner';
@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor() { }

  getHomePage(): Tile[] {
    return homeTiles;
  }
  getFeaturedProducts(): Product<ProductOptions[]>[] {
    return featuredWomens;
  }
  getInstagramProducts(): InstagramProduct<any>[] {
    return instagramTiles;
  }
  getPageTiles(): ButtonTile[]  {
    return featuredTiles;
  }
  getPageCarousel(): ExtendedTile[] {
    return banner;
  }
}
