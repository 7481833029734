export enum EnvironmentType {
    local, stage, development, production
}
export interface PulseEnvironmentVariables {    
    GOOGLE_ANALYTICS_ID: string;
}
// @TODO Merge with same version in Lib
export interface DomainHandlerConfiguration extends PulseEnvironmentVariables {
    windowHost: string;
    cookieDomain: string;
    whiteListDomain: string;
    apiUrl: string;
    environment: EnvironmentType;
}
export interface PulseEnvironment {
    domains: DomainHandlerConfiguration[];
}
