import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ContentNavigationService } from '../services/content-navigation.service';
import { ICategory } from '../../layout/models/bared-menu';

@Directive({
  selector: '[baredNavigatesTo]'
})
export class NavigatesToDirective {

  @Input('baredNavigatesTo') url: ICategory;
  // @Input('pageId') pageId: number;
  constructor(private el: ElementRef, private navigationService: ContentNavigationService) {

  }
  @HostListener('click', ['$event']) onClick($event){
    console.info('clicked: ' + $event);
    console.log(this.url);
    this.navigationService.navigate(this.url);
  }
}
