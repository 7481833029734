import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatButtonModule,
  MatRadioModule,
  MatMenuModule,
  MatIconModule,
  MatTooltipModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatDividerModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatSidenavModule,
  MatSliderModule,
  MatChipsModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatGridListModule,
  MatBottomSheetModule
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatButtonModule,
    MatRadioModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatDividerModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatGridListModule
  ],
  exports: [
    MatButtonModule,
    MatRadioModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatDividerModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatChipsModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatGridListModule,
    MatBottomSheetModule
  ]
})
export class MaterialInterfaceModule { }
