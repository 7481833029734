import {BaredDomainHandlerConfiguration, BaredEnvironment } from '../app/shared/bared-configuration/models/bared-domains';
import {BaredConfig} from '../app/shared/bared-configuration/models/bared-configuration';
import { EnvironmentType } from 'src/app/bared-foundation/config/models/app-environments';


export const domains: BaredDomainHandlerConfiguration[] = [
    {
        windowHost: 'uat.bared.com',
        environment: EnvironmentType.stage,
        cookieDomain: 'stage.bared.com',
        whiteListDomain: 'api.stage.bared.com',
        apiUrl: 'https://api.stage.bared.com/api/',
        GOOGLE_ANALYTICS_ID: 'UA-106788019-1',
        awsS3Endpoint: 'https://s3-ap-southeast-2.amazonaws.com/bared-uat-storage/media/'
    },
    {
        windowHost: 'dev.bared.com.au',
        cookieDomain: 'dev.bared.com.au',
        whiteListDomain: 'api.dev.bared.com.au',
        apiUrl: 'https://api.dev.bared.com.au/api/',
        GOOGLE_ANALYTICS_ID: 'UA-106791666-1',
        environment: EnvironmentType.development,
        awsS3Endpoint: 'https://s3-ap-southeast-2.amazonaws.com/bared-prod-storage/media/'
    },
    {
        windowHost: 'dev.bared.com.au.s3-website-ap-southeast-2.amazonaws.com',
        cookieDomain: 'dev.bared.com.au.s3-website-ap-southeast-2.amazonaws.com',
        whiteListDomain: 'api.dev.bared.com',
        apiUrl: 'https://api.dev.bared.com/api/',
        GOOGLE_ANALYTICS_ID: 'UA-106791666-1',
        environment: EnvironmentType.development,
        awsS3Endpoint: 'https://s3-ap-southeast-2.amazonaws.com/bared-prod-storage/media/'
    },
    {
        windowHost: 'bared.com',
        cookieDomain: 'bared.com',
        whiteListDomain: 'api.bared.com',
        apiUrl: 'https://api.bared.com/api/',
        GOOGLE_ANALYTICS_ID: 'UA-86021919-1',
        environment: EnvironmentType.production,
        awsS3Endpoint: 'https://s3-ap-southeast-2.amazonaws.com/bared-prod-storage/media/'
    },
    {
        windowHost: 'localhost',
        cookieDomain: 'localhost:4200',
        whiteListDomain: 'localhost:5000',
        apiUrl: 'http://localhost:5000/api/',
        GOOGLE_ANALYTICS_ID: 'UA-106791666-1',
        environment: EnvironmentType.local,
        awsS3Endpoint: 'https://s3-ap-southeast-2.amazonaws.com/bared-prod-storage/media/'
    },
    {
        windowHost: 'pulse.local',
        cookieDomain: 'pulse.local',
        whiteListDomain: 'localhost:5000',
        apiUrl: 'http://localhost:5000/api/',
        GOOGLE_ANALYTICS_ID: 'UA-106791666-1',
        environment: EnvironmentType.local,
        awsS3Endpoint: 'https://s3-ap-southeast-2.amazonaws.com/bared-prod-storage/media/'
    }]
    ;
export class ApplicationConfig implements BaredConfig, BaredEnvironment {
    public domains = domains;
    public apiUrl = '';
    public apiVersion = '';
    public schemaVersion = '';
    public registeredUrls: [];
    public production = false; 
    public baseURL = '//localhost:5000';
    public googleTrackingID: string;
    public jwtKey = 'access_token';
    public defaultLocaleKey = 'defaultLocale';
    public defaultCultureKey = 'defaultCulture';
    public appName = 'Bared';
    public hideDelay = 3000;
    public awsS3Domain = '';
    public placeholders: {
        homepage: '/assets/images/bared/homepage-placeholder.jpg';
        banner: '/assets/images/bared/banner-placeholder.jpg';
        title: '/assets/images/bared/tile-placeholder.jpg';
        product: '/assets/images/bared/product-placeholder.jpg';        
    }
        public constructor() {
        // set Current Environment
        const domain = this.domains.find(x => x.windowHost === window.location.hostname);
        if (domain === undefined) {
            throw (new Error('Can not build a configuration for the current environment. Unknown host: ' + window.location.hostname));
        }
        this.baseURL = domain.apiUrl;
        this.apiUrl = domain.apiUrl;
        this.awsS3Domain = domain.awsS3Endpoint;
        this.googleTrackingID = domain.GOOGLE_ANALYTICS_ID;
        
    }
}
export const baredConfiguration = new ApplicationConfig();
