import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingCartSidebarComponent } from './components/shopping-cart-sidebar/shopping-cart-sidebar.component';
import { MaterialInterfaceModule } from '../../shared/material-interface/material-interface.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckoutComponent } from './components/checkout/checkout.component';



@NgModule({
  declarations: [ShoppingCartSidebarComponent, CheckoutComponent],
  imports: [
    CommonModule,
    MaterialInterfaceModule,
    FormsModule
  ],
  exports: [ShoppingCartSidebarComponent]
})
export class CartModule { }
