import { Component, OnInit, Input } from '@angular/core';
import { faPinterestP, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ICategory } from '../../models/bared-menu';

@Component({
  selector: 'bared-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  @Input() footer1: ICategory[] = [];
  @Input() footer2: ICategory[] = [];
  @Input() footer3: ICategory[] = [];  
  public iPinterest = faPinterestP;
  public iFacebook = faFacebookF;
  public iInstagram = faInstagram;
  public testMode = false;
  constructor() { }

  ngOnInit() {
  }
  public gotoItem(item: any) {

  }
  public globalSet(countryCode: string) {
    
  }
}
