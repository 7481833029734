import { ProductOptions, Product } from 'src/app/bared-shop/product/models/product'

export const womensResult = {
    "result": [
      {
        "id": 10961,
        "parent_id": null,
        "name": "Lyrebird",
        "uri_path": "Lyrebird-Mustard-Suede",
        "desc_short": "Mustard Suede",
        "desc_full": "<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>A strappy sensation\u00a0- meet our 'Lyrebird'.\u00a0Designed with simple styling in mind, this easy-to-wear summer sandal will become your season staple.\u00a0Crafted with two slim straps, and finished with metallic\u00a0detailing - you can make a statement with minimal effort.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>STYLE TIP<\/b><\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Add a little sunshine to your week, pair with shorts.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>FEATURES<\/b><\/p><ul type='disc' style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><li>Bared's unique in-built biomechanical footbed has all the support you need, no other fitting options included.<\/li><li>Buckled straps for an adjustable fit around the ankle.<\/li><li>12mm pitch to prevent lower leg pain.<\/li><li>Nubuck upper, leather lined, rubber sole, leather-lined footbed.<\/li><li>Metal hardware colour: Gold<\/li><\/ul>",
        "tags": ['wide feet','narrow feet','mustard','metallic'],
        "images": [
          "1567994363541_BARED_FOOTWEAR_Lyrebird_Mustard-Suede_229AUD_1.jpg",
          "1567994362787_BARED_FOOTWEAR_Lyrebird_Mustard-Suede_229AUD_2.jpg",
          "1567994363139_BARED_FOOTWEAR_Lyrebird_Mustard-Suede_229AUD_4.jpg",
          "1567994362392_BARED_FOOTWEAR_Lyrebird_Mustard-Suede_229AUD_3.jpg",
          "1568357850433_baredfootwear_spring19-19.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              34,
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43
            ]
          }
        ],
        "price": 229.00,
        "price_international": null,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 0,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.8,
        "yotpo_id": 323,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2019-06-13 11:19:34",
        "updated_on": "2019-09-13 16:57:32"
      },
      {
        "id": 11028,
        "parent_id": null,
        "name": "Stint",
        "uri_path": "Stint-Olive-Suede",
        "desc_short": "Olive Suede",
        "desc_full": "<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Warm-weather approved, we'd like to meet the 'Stint'!\u00a0The latest in espadrilles, this summer\u00a0essential will walk you through the season.\u00a0Showcasing a unique heel panel, woven with natural yarns for a speckled effect, and fitted with wrap-around ribbon ties for a flattering finish.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b><br\/>STYLE TIP<br\/><\/b>Go khaki! Pair with anything from floaty dresses, to denim.<br\/><br\/><b>FEATURES<\/b><\/p><ul><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Our in-built biomechanical footbed has all the support you need, no other fitting options included.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>83mm wedge heel with 15mm platform.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Ankle tie fastening.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Suede and ribbon upper, synthetic sole, leather lining, leather-lined footbed.<\/li><\/ul>",
        "tags": ["high heel","green","olive","narrow feet","wide feet"],
        "images": [
          "1568341687225_BARED_FOOTWEAR_Stint_Olive-Suede_259AUD_2.jpg",
          "1567994288444_BARED_FOOTWEAR_Stint_Olive-Suede_259AUD_1.jpg",
          "1567994211422_BARED_FOOTWEAR_Stint_Olive-Suede_259AUD_3.jpg",
          "1568357904136_baredfootwear_springstudio-67.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              34,
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43
            ]
          }
        ],
        "price": 259.00,
        "price_international": null,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 0,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.8,
        "yotpo_id": 372,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2019-06-13 11:19:42",
        "updated_on": "2019-09-13 16:58:26"
      },
      {
        "id": 10680,
        "parent_id": null,
        "name": "Lory",
        "uri_path": "Lory-Mustard-Suede",
        "desc_short": "Mustard Suede",
        "desc_full": "<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>The 'Dunnock' you love, with a softer toe - meet the 'Lorry'! Designed for casual wear, this well-rounded beauty will become your next staple.\u00a0Channel your inner-boho and step out in style.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>STYLE TIP<\/b><\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Your vacay starts here, pair with linen.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>FEATURES<\/b><\/p><ul style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Bared's unique in-built biomechanical footbed.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Ankle strap to secure you in the shoe and prevent toes from clawing.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>23mm stable heel.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Suede upper, rubber sole, leather lining, leather lined footbed.<\/li><\/ul>",
        "tags": ['wide feet','narrow feet','yellow','mustard','closed toe'],
        "images": [
          "1563339288846_BARED_FOOTWEAR_Lory_MustardSuede_249AUD_1.jpg",
          "1563339289924_BARED_FOOTWEAR_Lory_MustardSuede_249AUD_2.jpg",
          "1563339290697_BARED_FOOTWEAR_Lory_MustardSuede_249AUD_3.jpg",
          "1568357789086_baredfootwear_spring19-15.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              34,
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43
            ]
          }
        ],
        "price": 259.00,
        "price_international": null,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 0,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.8,
        "yotpo_id": 354,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2019-05-24 01:41:05",
        "updated_on": "2019-09-13 16:56:38"
      },
      {
        "id": 11017,
        "parent_id": null,
        "name": "Stint",
        "uri_path": "Stint-Nude-Tan-Leather",
        "desc_short": "Nude Tan Leather",
        "desc_full": "<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Warm-weather approved, we'd like to meet the 'Stint'!\u00a0The latest in espadrilles, this summer\u00a0essential will walk you through the season.\u00a0Showcasing a unique heel panel, woven with natural yarns for a speckled effect, and fitted with wrap-around ribbon ties for a flattering finish.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>STYLE TIP<br\/><\/b>Put it in neutral, pair with a white tee and a denim skirt.<br\/><br\/><b>FEATURES<\/b><\/p><ul style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Our in-built biomechanical footbed has all the support you need, no other fitting options included.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>83mm wedge heel with 15mm platform.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Ankle tie fastening.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Leather and ribbon upper, synthetic sole, leather lining, leather-lined footbed.<\/li><\/ul>",
        "tags": ['wide feet','narrow feet','tan','taupe','brown','high heel'],
        "images": [
          "1567994311489_BARED_FOOTWEAR_Stint_Nude-Tan_259AUD_2.jpg",
          "1567994312243_BARED_FOOTWEAR_Stint_Nude-Tan_259AUD_1.jpg",
          "1568357922054_baredfootwear_springstudio-28.jpg",
          "1568357922715_baredfootwear_springstudio-32.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              34,
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43
            ]
          }
        ],
        "price": 259.00,
        "price_international": null,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 0,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.800,
        "yotpo_id": 372,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2019-06-13 11:19:41",
        "updated_on": "2019-09-13 13:19:11"
      },
      {
        "id": 7636,
        "parent_id": null,
        "name": "Dunnock",
        "uri_path": "Dunnock-Black-Leather",
        "desc_short": "Black Leather",
        "desc_full": "<p>When sandals meet flats - the 'Dunnock' was born! Designed for the gal on the go, and finished with a pointed toe - dress up any casual outfit with ease. Channel your inner-boho and step out in style, it's the flat you never knew you needed.<\/p><p><b>STYLE TIP<\/b><\/p><p>Keep it simple - pair with white linen.<\/p><p><b>FEATURES<\/b><\/p><p><\/p><ul style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Bared's unique in-built biomechanical footbed.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Ankle strap to secure you in the shoe and prevent toes from clawing.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>23mm stable heel.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Leather upper, rubber sole, leather lining, leather lined footbed.<\/li><\/ul><p><\/p>",
        "tags": ['wide feet','narrow feet','black'],
        "images": [
          "1547428927956_BARED_FOOTWEAR_Dunnock_Black_229AUD_2.jpg",
          "1547428928281_BARED_FOOTWEAR_Dunnock_Black_229AUD_3.jpg",
          "1547428928703_BARED_FOOTWEAR_Dunnock_Black_229AUD_4.jpg",
          "1547428926979_BARED_FOOTWEAR_Dunnock_Black_229AUD_1.jpg",
          "1568357886889_baredfootwear_springstudio-71.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43,
              34
            ]
          }
        ],
        "price": 259.00,
        "price_international": 169.00,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 1,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.8,
        "yotpo_id": 344,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2018-11-07 15:37:07",
        "updated_on": "2019-09-13 16:58:10"
      },
      {
        "id": 7646,
        "parent_id": null,
        "name": "Dunnock",
        "uri_path": "Dunnock-Tan-Leather",
        "desc_short": "Tan Leather",
        "desc_full": "<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>When sandals meet flats - the 'Dunnock' was born! Designed for the gal on the go, and finished with a pointed toe - dress up any casual outfit with ease. Channel your inner-boho and step out in style, it's the flat you never knew you needed.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>STYLE TIP<\/b><\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>The hue that goes with everything - pair with floral for a fresh look.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>FEATURES<\/b><\/p><ul style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Bared's unique in-built biomechanical footbed.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Ankle strap to secure you in the shoe and prevent toes from clawing.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>23mm stable heel.<\/li><li style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Leather upper, rubber sole, leather lining, leather lined footbed.<\/li><\/ul>",
        "tags": ['wide feet','narrow feet','tan','taupe','brown'],
        "images": [
          "1551130569702_BARED_FOOTWEAR_Dunnock_Tan_229AUD_2.jpg",
          "1551130503207_BARED_FOOTWEAR_Dunnock_Tan_229AUD_4.jpg",
          "1551130503933_BARED_FOOTWEAR_Dunnock_Tan_229AUD_1.jpg",
          "1551130502393_BARED_FOOTWEAR_Dunnock_Tan_229AUD_3.jpg",
          "1559624157514_BARED_ON-FEET_Dunnock_Tan-1.jpg",
          "1568357872360_baredfootwear_springstudio-19.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43,
              34
            ]
          }
        ],
        "price": 259.00,
        "price_international": 169.00,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 1,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.8,
        "yotpo_id": 344,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2018-11-07 15:37:07",
        "updated_on": "2019-09-13 16:57:54"
      },
      {
        "id": 9731,
        "parent_id": null,
        "name": "Sparrow",
        "uri_path": "Sparrow-Mustard-Suede",
        "desc_short": "Mustard Suede",
        "desc_full": "<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Get sunshine ready!\u00a0A minimalistic take on the classic espadrille, refine your look in the 'Sparrow'. Fusing\u00a0contemporary elegance with a modern twist, this silhouette style wedge will take you right through summer.<\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>STYLE TIP<\/b><br\/><\/p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Elevate your look! Pair with flowing skirts, or a little black dress.<\/p><div style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>FEATURES<\/b><br\/><ul><li>Bared's unique in-built biomechanical footbed has all the support you need, no other fitting options included.<\/li><li>Supportive ankle strap.<\/li><li>67mm wedge heel with 17mm platform.<\/li><li>Suede upper, synthetic sole, leather lined footbed.<\/li><\/ul><\/div>",
        "tags": ['wide feet','narrow feet','yellow','tan','taupe','wedges','open toe'],
        "images": [
          "1557965219108_BARED_FOOTWEAR_Sparrow_Mustard-Suede_249AUD_3.jpg",
          "1557972911437_BARED_FOOTWEAR_Sparrow_Mustard-Suede_249AUD_2.jpg",
          "1558060751087_BARED_FOOTWEAR_Sparrow_Mustard-Suede_249AUD_1.jpg",
          "1568357822600_baredfootwear_spring19-17.jpg"
        ],
        "options": [
          {
            "label": "Size",
            "values": [
              34,
              35,
              36,
              37,
              38,
              39,
              40,
              41,
              42,
              43
            ]
          }
        ],
        "price": 259.00,
        "price_international": null,
        "price_international_sale": null,
        "price_sale": null,
        "price_sale_vip": null,
        "is_on_sale": false,
        "sku": null,
        "stock": 0,
        "stock_shop_1": 0,
        "stock_shop_2": 0,
        "stock_shop_3": 0,
        "stock_shop_6": 0,
        "presale_active": false,
        "presale_max_amount": 0,
        "presale_stock": 0,
        "presale_sku": null,
        "status": 1,
        "show_notify_out_of_stock": false,
        "enabled": true,
        "status_previous": 0,
        "width": 0.00,
        "height": 0.00,
        "depth": 0.00,
        "weight": 1.8,
        "yotpo_id": 304,
        "matrix_id": 0,
        "restockable": true,
        "created_on": "2019-03-22 12:18:13",
        "updated_on": "2019-09-13 16:57:05"
      }
    ]
  }

  export const featuredWomens: Product<ProductOptions[]>[] = womensResult.result;

