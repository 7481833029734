import { Component } from '@angular/core';
import { SideBars } from './shared/layout/models/layout.constants';
import { IBaredMenuItem } from './shared/layout/models/bared-menu';
import { mockedMenu } from './shared/layout/mocks/menu-mock';
import {footer1, footer2, footer3 } from './shared/layout/mocks/footer-menu-mock';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'bared-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // AppState and AppState Components
  // Can change Navigation via the navigate EventEmitter
  // Can Change Menu State via the openSidebar Event Emitter
  public openMobile = false;
  public openCart = false;
  public readonly categoryPrefix = "Category";
  public menu: IBaredMenuItem[] = mockedMenu;
  public footer1 = footer1;
  public footer2 = footer2;
  public footer3 = footer3;
  public showMenu = true;
  constructor(private router: Router) { 
    this.router.events.subscribe(event => {
      
      if(event instanceof NavigationEnd) {
        console.log(event);
        this.showMenu = event.url !== '/';
      }
    });
  }
  public openSidebar(event: SideBars) {
    console.log('should open with', event);
    switch (event) {
      case SideBars.ShoppingCart:
        this.openCart = !this.openCart;
        console.log(this.openCart);
        break 
      case SideBars.MobileMenu:
        this.openMobile = !this.openMobile;
        break;
    }
    
  }
  public navigate(routeParams: string[]) {
    console.log(routeParams);
  }
}
