import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NavigatesToDirective } from './directives/navigates-to.directive';



@NgModule({
  declarations: [NavigatesToDirective],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    NavigatesToDirective
  ]
})
export class ContentNavigationModule {   
}
