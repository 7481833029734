import { Component, OnInit } from '@angular/core';
import { ImagePlaceholderType } from 'src/app/shared/bared-configuration/models/bared-configuration';
import { ContentService } from '../../services/content.service';
import { Tile } from '../../models/tile-model';
import { ContentNavigationService } from 'src/app/shared/content-navigation/services/content-navigation.service';

@Component({
  selector: 'bared-home-page',
  templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit {
  public items: Tile[] = [];
  public placeholderType = ImagePlaceholderType.homepage;
  constructor(private contentService: ContentService, private navigator: ContentNavigationService) { }

  ngOnInit() {
    this.items = this.contentService.getHomePage();    
  }
  navigateTo(url: string) {
    this.navigator.navigateTo([url]);
  }

}
