
import { Tile } from '../models/tile-model';
export const TileResult = {
    "result": [
      {
        "id": "1",
        "label": "WOMEN'S",
        "image": "1566484988565_Landing-Page---AW19-06.jpg",
        "url": "women",
        "sort": "1"
      },
      {
        "id": "2",
        "label": "MEN'S",
        "image": "1566485001443_Photo-2-5-19,-1-31-44-pm.jpg",
        "url": "men",
        "sort": "2"
      }
    ]
  }

  export const homeTiles: Tile[] = TileResult.result;