import { ShoppingCartItem } from '../models/shopping-cart';
import { ProductSizeOption, Product, ProductOptions } from 'src/app/bared-shop/product/models/product';

export const ProductOneParent: Product<ProductOptions[]> = {
    created_on: '2018-12-20 17:01:36',
    depth: 0.000,
    desc_full: `<p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>It’s the ‘Corella’ heels you love, one step higher. Introducing the ‘Kea’! An ultra classic style, designed to elongate the leg with a delicate ankle strap. You’ll walk straight through the seasons with this timeless pointed toe.</p>
                <p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>STYLE TIP</b><br/></p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>Go red! Pop some colour, and pair with all black.</p>
                <div style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>FEATURES:</b><br/><ul><li>Bared's unique inbuilt biomechanical footbed has all the support you need, no other fitting options included.</li>
                <li>75mm heel height.</li><li>Adjustable &amp; supportive ankle straps.</li><li>Rubber outsole on the forefoot for added grip and cushioning.</li><li>Nubuck upper, resin and rubber sole, leather-lined footbed.</li></ul><p><br/></p><p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'><b>FITTING TIP</b></p>
                <p style='color: rgba(0, 0, 0, 0.87);font-size: 16px;'>If you’re worried your shoes are too big, don’t be alarmed! In pointed-toe styles, we extend the length to ensure your toes aren’t squashed in the point. We recommend a 1–2cm space between the end of your toes and the end of the point, when your heel is pushed back into the heel counter.</p></div>`,
    desc_short: 'Chilli Nubuck',
    enabled: true,
    height: 0.000,
    id: 8935,
    is_on_sale: false,
    matrix_id: 0,
    name: 'Kea',
    options: [
        {
            label: 'Size', 
            values: ['34', '35', '36', '37', '38', '39', '40', '41', '42', '43'],
            stocks: [0, 4, 1, 17, 10, 10, 9, 4, 4, 1]
        }
    ],
    images: [],
    parent: null,
    parent_id: null,
    presale_active: false,
    presale_max_amount: 0,
    presale_sku: null,
    presale_stock: 0,
    price: 249.00,
    price_international: null,
    price_international_sale: null,
    price_sale: null,
    price_sale_vip: null,
    restockable: true,
    show_notify_out_of_stock: false,
    sku: null,
    status: 1,
    status_previous: 1,
    stock: 0,
    stock_shop_1: 0,
    stock_shop_2: 0,
    stock_shop_3: 0,
    stock_shop_6: 0,
    tags: ['closed toe','high heel','red','wide feet'],
    updated_on: '2019-06-12 14:54:53',
    uri_path: 'Kea-Chilli-Nubuck',
    weight: 1.800,
    width: 0.000,
    yotpo_id: 297
};

export const ProductOne: Product<ProductSizeOption> = {
    created_on: '2018-12-20 17:01:36',
    depth: null,
    desc_full: null,
    desc_short: 'Chilli Nubuck',
    enabled: true,
    height: null,
    id: 8944,
    images: ['1555560953101_BARED_FOOTWEAR_Kea_ChilliNbk_249AUD_3.jpg', '1555560953927_BARED_FOOTWEAR_Kea_ChilliNbk_249AUD_2.jpg','1555560954203_BARED_FOOTWEAR_Kea_ChilliNbk_249AUD_1.jpg'],
    is_on_sale: false,
    matrix_id: 293,
    name: 'Kea',
    options: { Size: '42'},
    parent: ProductOneParent,
    parent_id: 8935,
    presale_active: false,
    presale_max_amount: 0,
    presale_sku: null,
    presale_stock: 0,
    price: 249.00,
    price_international: 0.00,
    price_international_sale: null,
    price_sale: null,
    price_sale_vip: null,
    restockable: true,
    show_notify_out_of_stock: false,
    sku: '14042',
    status: 1,
    status_previous: 1,
    stock: 4,
    stock_shop_1: 3,
    stock_shop_2: 0,
    stock_shop_3: 1,
    stock_shop_6: 0,
    tags: null,
    updated_on: null,
    uri_path: null,
    weight: null,
    width: null,
    yotpo_id: null
}

export const MockCart: ShoppingCartItem[] = [ 
    {
        created_on: '2019-09-19 11:56:06',
        currency_id: 1,
        giftcard: null,
        id: 327228,
        price: 249,
        product: ProductOne,
        product_id: 8944,
        quantity: 1,
        token: 'CMYBsBDxWI7GciKDNK4mYPjCASsXpRDJNkHC8evoXk',
        user_id: 63568
    }
]