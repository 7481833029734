import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ContentService } from './services/content.service';
import { MaterialInterfaceModule } from '../material-interface/material-interface.module';
import { LayoutModule } from '../layout/layout.module';
import { TiledPageComponent } from './components/tiled-page/tiled-page.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProductModule } from 'src/app/bared-shop/product/product.module';
import { FilteredItemListComponent } from './components/filtered-item-list/filtered-item-list.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { GridListComponent } from './components/grid-list/grid-list.component';
import { InstagramComponent } from './components/instagram/instagram.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { AllGridListComponent } from './components/all-grid-list/all-grid-list.component';
import { ContentComponent } from './components/content/content.component';


@NgModule({
  declarations: [HomePageComponent,
                 TiledPageComponent,
                 FilteredItemListComponent,
                 ItemDetailsComponent,
                 GridListComponent,
                 InstagramComponent,
                 ContactUsComponent,
                 AllGridListComponent,
                 ContentComponent],
  imports: [
    CommonModule,
    MaterialInterfaceModule,
    LayoutModule,
    SlickCarouselModule,
    ProductModule
  ],
  providers: [
    ContentService
  ],
  exports: [HomePageComponent,
    TiledPageComponent,
    ItemDetailsComponent,
    GridListComponent,
    InstagramComponent,
    ContactUsComponent,
    AllGridListComponent,
    ContentComponent]
})
export class ContentPageModule { }
