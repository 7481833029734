import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBaredMenuItem } from '../../models/bared-menu';
import { faCircle } from '@fortawesome/free-solid-svg-icons'
@Component({
  selector: 'bared-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  @Input() menu: IBaredMenuItem[] = [];
  @Input() categoryPrefix = "Category";
  @Output() navigate = new EventEmitter<string[]>();
  public faCircle = faCircle;
  public colours = [ {
    hex: '#5F5D6B',
    name: 'Black leather'
  },
  {
    'hex': '#76949E',
    'name': 'teal'
  },
  {
    'hex': '#E3A834',
    'name': 'mustard'
  },
  {
    'hex': '#E76448',
    'name': 'burnt-orange'
  }
  ];
  public showMultiMenu = false;
  public selectedMenuIndex = -1;
  public selectedMenuItem: IBaredMenuItem;
  public childItems: IBaredMenuItem[];
  constructor() { }

  ngOnInit() {
  }
  // I think element is native - need to get positioning for drop down
  public setMenu(index: number, menuItem: IBaredMenuItem, element: any) {
    this.selectedMenuIndex = this.selectedMenuIndex === index ? -1 : index;
    this.selectedMenuItem = menuItem;
    this.showMultiMenu = this.selectedMenuIndex !== -1;
    this.childItems = this.selectedMenuIndex === -1 ? [] : menuItem.Children;
  }
  // nicer to handle this with a service I think .. and change the logic to emit IBaredMenuItem
  // Needs to be single pattern of logic!
  public navigateTo(item: IBaredMenuItem) {
    switch (item.Category.url.type) {
      case 'category':
          this.navigate.emit( [this.categoryPrefix, item.Category.url.value.toString()]);
          break;
      case 'page':
          this.navigate.emit( [item.Category.url.value.toString()]);
          break;
      case 'static':
          this.navigate.emit( [item.Category.url.value.toString()]);
          break;
      }
  }
}
