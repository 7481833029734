import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MockCart } from '../../mocks/mock-cart';
import { SideBars } from 'src/app/shared/layout/models/layout.constants';

@Component({
  selector: 'bared-shopping-cart-sidebar',
  templateUrl: './shopping-cart-sidebar.component.html'
})
export class ShoppingCartSidebarComponent implements OnInit {
  @Output() openSidebar = new EventEmitter<SideBars>();
  public cart = MockCart;
  public total = 249;
  public currency = {
    symbol: '$',
    rate: 1,
    value: 'AUD'
  }
  constructor() { }

  ngOnInit() {
  }
  public closeSidebar() {
    this.openSidebar.emit(SideBars.ShoppingCart);
  }
  public navigateCheckout() {

  }
  public confirmRemove(id: number) {

  }
}
