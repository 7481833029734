import { Component, OnInit, HostListener } from '@angular/core';
import { Product, ProductOptions } from 'src/app/bared-shop/product/models/product';
import { Tile, ExtendedTile, ButtonTile } from '../../models/tile-model';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'bared-tiled-page',
  templateUrl: './tiled-page.component.html'
})
export class TiledPageComponent implements OnInit {

  featuredProducts: Product<ProductOptions[]>[] = [];
  instagramProducts = [];
  carousel: ExtendedTile[] = [];
  tiles: ButtonTile[] = [];
  public gridColumns = 3;
  private breakpoints = [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 961,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }];
  public slideConfig = {"slidesToShow": 6, 'arrows':true, "slidesToScroll": 3, "responsive": this.breakpoints};
  public heroCarouselConfig = {"slidesToShow": 1, 'adaptiveHeight': true, 'arrows':true, "slidesToScroll": 1};
  
 
  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.gridColumns = window.innerWidth <= 600 ? 1 : 3;
    this.featuredProducts = this.contentService.getFeaturedProducts();
    this.instagramProducts = this.contentService.getInstagramProducts();
    this.tiles = this.contentService.getPageTiles();
    this.carousel = this.contentService.getPageCarousel();
  }
  @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.gridColumns = window.innerWidth <= 600 ? 1 : 3;
    }
  addSlide() {
    // this.featuredProducts.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.featuredProducts.length = this.featuredProducts.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }

}
