import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { MenuComponent } from './components/menu/menu.component';
import { MaterialInterfaceModule } from '../material-interface/material-interface.module';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SearchBottomSheetComponent } from './components/search-bottom-sheet/search-bottom-sheet.component';
import { ExpandedMenuComponent } from './components/expanded-menu/expanded-menu.component';
import { AwsS3Pipe } from './pipes/aws-s3.pipe';
import { ContentNavigationModule } from '../content-navigation/content-navigation.module';


@NgModule({
  declarations: [TopBannerComponent, MenuComponent, FooterComponent, HeaderComponent, SideMenuComponent, SearchBottomSheetComponent, ExpandedMenuComponent, AwsS3Pipe],
  imports: [
    CommonModule,
    MaterialInterfaceModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    ContentNavigationModule
  ],
  exports: [TopBannerComponent, MenuComponent, FooterComponent, HeaderComponent, SideMenuComponent, AwsS3Pipe],
  entryComponents: [SearchBottomSheetComponent]
})
export class LayoutModule { }
