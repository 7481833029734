import { Pipe, PipeTransform, Inject } from '@angular/core';
import { ImagePlaceholderType } from '../../bared-configuration/models/bared-configuration';
import { APP_CONFIG, AppConfiguration } from '../../bared-configuration/models/app-config';
@Pipe({
  name: 'awsS3'
})
export class AwsS3Pipe implements PipeTransform {

  constructor(@Inject(APP_CONFIG) private config: AppConfiguration) {

  }
  transform(value: any, type: ImagePlaceholderType ): any {
    
    return value ? (this.config.awsS3Domain + value) : this.getPlaceholder(type);
  }
  private getPlaceholder(type: ImagePlaceholderType): string {
    switch (type) {
      case ImagePlaceholderType.banner:
        return this.config.placeholders.banner;
      case ImagePlaceholderType.homepage:
          return this.config.placeholders.homepage;
      case ImagePlaceholderType.product:
          return this.config.placeholders.product;
      case ImagePlaceholderType.title:
          return this.config.placeholders.title;
      default: 
        return this.config.placeholders.title;
    }
  }
  
}
