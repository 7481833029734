import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './shared/content-page/components/home-page/home-page.component';
import { TiledPageComponent } from './shared/content-page/components/tiled-page/tiled-page.component';
import { ContactUsComponent } from './shared/content-page/components/contact-us/contact-us.component';
import { LoginComponent } from './bared-customer/account/components/login/login.component';
import { ResetPasswordComponent } from './bared-customer/account/components/reset-password/reset-password.component';
import { RegisterComponent } from './bared-customer/account/components/register/register.component';
import { WishlistComponent } from './bared-shop/wishlist/wishlist/wishlist.component';
import { CheckoutComponent } from './bared-shop/cart/components/checkout/checkout.component';
import { OrderComponent } from './bared-shop/order/components/order/order.component';
import { ContentPageModule } from './shared/content-page/content-page.module';
import { GridListComponent } from './shared/content-page/components/grid-list/grid-list.component';
import { AllGridListComponent } from './shared/content-page/components/all-grid-list/all-grid-list.component';
import { ProductDetailComponent } from './bared-shop/product/components/product-detail/product-detail.component';
import { FilteredItemListComponent } from './shared/content-page/components/filtered-item-list/filtered-item-list.component';
import { GiftCardComponent } from './bared-shop/product/components/gift-card/gift-card.component';
import { InstagramComponent } from './shared/content-page/components/instagram/instagram.component';
import { MyAccountComponent } from './bared-customer/account/components/my-account/my-account.component';
import { ContentComponent } from './shared/content-page/components/content/content.component';

// add Roles
// Load Meta data with platform browser and nav service
// Set Test Mode () - non production only. Should require signin!
const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomePageComponent , data: { icon: 'home', title: 'home', id: 1, parentId: 0, isTopLevel: false} },
  { path: 'women', pathMatch: 'full', component: TiledPageComponent , data: { icon: 'home', title: 'womens', id: 1, parentId: 0, isTopLevel: false} },
  { path: 'men', pathMatch: 'full', component: TiledPageComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false} },
  { path: 'category/:slug', pathMatch: 'full', component: GridListComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'all/:slug', pathMatch: 'full', component: AllGridListComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'product/:slug', pathMatch: 'full', component: ProductDetailComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'search/:slug', pathMatch: 'full', component: FilteredItemListComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'giftcards', pathMatch: 'full', component: GiftCardComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'instagram', pathMatch: 'full', component: InstagramComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'contact-us', pathMatch: 'full', component: ContactUsComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'login', pathMatch: 'full', component: LoginComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'reset', pathMatch: 'full', component: ResetPasswordComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'signup', pathMatch: 'full', component: RegisterComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'account', pathMatch: 'full', component: MyAccountComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'wishlist', pathMatch: 'full', component: WishlistComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'checkout', pathMatch: 'full', component: CheckoutComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'order/{:id}', pathMatch: 'full', component: OrderComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'content/:slug', pathMatch: 'full', component: ContentComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'test', pathMatch: 'full', component: TiledPageComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  // May not be needed
  { path: 'eway/:id', pathMatch: 'full', component: TiledPageComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
  { path: 'paypal/:id', pathMatch: 'full', component: TiledPageComponent , data: { icon: 'home', title: 'mens', id: 1, parentId: 0, isTopLevel: false}  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
