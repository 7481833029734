import { IConfig } from 'src/app/bared-foundation/config/models/i-config';

export enum ImagePlaceholderType {
    homepage = 1,
    banner = 2,
    title = 3,
    product = 4
}

export interface BaredConfig extends IConfig {
    placeholders: {
        homepage: string;
        banner: string;
        title: string;
        product: string;
    }
    awsS3Domain;
}
