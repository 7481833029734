import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IBaredMenuItem } from '../../models/bared-menu';
import { SideBars } from '../../models/layout.constants';

@Component({
  selector: 'bared-side-menu',
  templateUrl: './side-menu.component.html'
})
export class SideMenuComponent implements OnInit {

  @Input() menu: IBaredMenuItem[] = [];
  @Input() categoryPrefix = "Category";
  @Output() navigate = new EventEmitter<string[]>();
  @Output() openSidebar = new EventEmitter<SideBars>();
  public currentParent = -1;
  // Lets make this a config option and maybe set it via @Input
  constructor() { }

  ngOnInit() { }
  closeSidebar() {
    console.log('clicks');
    this.openSidebar.emit(SideBars.MobileMenu);
  }
  toggleChild(index: number) {
    this.currentParent = this.currentParent === index ? -1 : index;
    console.log( index, this.currentParent);
  }
  navigateCategory(category: string) {
    this.navigate.emit([this.categoryPrefix, category]);
  }
  navigateTo(url: string) {
    this.navigate.emit([url]);
  }
}
