import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialInterfaceModule } from './shared/material-interface/material-interface.module';
import { LayoutModule } from './shared/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CartModule } from './bared-shop/cart/cart.module';
import { APP_CONFIG } from './shared/bared-configuration/models/app-config';
import { baredConfiguration } from '../environments/app-config';
import { ContentPageModule } from './shared/content-page/content-page.module';
import { ContentNavigationService } from './shared/content-navigation/services/content-navigation.service';
import { ProductModule } from './bared-shop/product/product.module';
import { OrderModule } from './bared-shop/order/order.module';
import { WishlistModule } from './bared-shop/wishlist/wishlist.module';
import { AccountModule } from './bared-customer/account/account.module';
import { ContentNavigationModule } from './shared/content-navigation/content-navigation.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialInterfaceModule,
    LayoutModule,
    BrowserAnimationsModule,
    CartModule,
    ContentPageModule,
    ProductModule,
    OrderModule,
    WishlistModule,
    AccountModule,
    ContentNavigationModule
  ],
  providers: [
    {
      provide: APP_CONFIG, 
      useValue: baredConfiguration
    },
    ContentNavigationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
