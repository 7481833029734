import { ICategory } from '../models/bared-menu';

export const footer1: ICategory[] = [
      {
        "id": 46,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 36,
        "label": "FAQ",
        "url": {
          "type": "page",
          "value": 12
        },
        "status": 0,
        "created_on": "2016-11-08 14:04:56",
        "hide_main_menu": false
      },
      {
        "id": 47,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 37,
        "label": "SHOE CARE INSTRUCTIONS",
        "url": {
          "type": "page",
          "value": 13
        },
        "status": 0,
        "created_on": "2016-11-08 14:05:09",
        "hide_main_menu": false
      },
      {
        "id": 48,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 38,
        "label": "SHOP ONLINE WITH EASE",
        "url": {
          "type": "page",
          "value": 20
        },
        "status": 0,
        "created_on": "2016-11-08 14:05:16",
        "hide_main_menu": false
      },
      {
        "id": 43,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 39,
        "label": "SHIPPING INFORMATION",
        "url": {
          "type": "page",
          "value": 10
        },
        "status": 0,
        "created_on": "2016-11-08 14:04:07",
        "hide_main_menu": false
      },
      {
        "id": 44,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 40,
        "label": "RETURNS & EXCHANGES",
        "url": {
          "type": "page",
          "value": 9
        },
        "status": 0,
        "created_on": "2016-11-08 14:04:22",
        "hide_main_menu": false
      },
      {
        "id": 45,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 41,
        "label": "PAYMENT SECURITY",
        "url": {
          "type": "page",
          "value": 11
        },
        "status": 0,
        "created_on": "2016-11-08 14:04:36",
        "hide_main_menu": false
      },
      {
        "id": 73,
        "menu_id": 2,
        "parent_id": 100,
        "sort": 42,
        "label": "WEBSITE ISSUES - HELP",
        "url": {
          "type": "page",
          "value": 24
        },
        "status": 0,
        "created_on": "2017-03-10 16:56:42",
        "hide_main_menu": false
      }
    ];
export const footer2: ICategory[] = [
          {
            "id": 49,
            "menu_id": 3,
            "parent_id": null,
            "sort": 49,
            "label": "ORTHOTIC-FRIENDLY SHOES",
            "url": {
              "type": "page",
              "value": 4
            },
            "status": 0,
            "created_on": "2016-11-08 14:06:26",
            "hide_main_menu": false
          },
          {
            "id": 50,
            "menu_id": 3,
            "parent_id": null,
            "sort": 50,
            "label": "WIDE FEET",
            "url": {
              "type": "page",
              "value": 19
            },
            "status": 0,
            "created_on": "2016-11-08 14:06:36",
            "hide_main_menu": false
          },
          {
            "id": 51,
            "menu_id": 3,
            "parent_id": null,
            "sort": 51,
            "label": "NARROW FEET",
            "url": {
              "type": "page",
              "value": 7
            },
            "status": 0,
            "created_on": "2016-11-08 14:06:48",
            "hide_main_menu": false
          },
          {
            "id": 53,
            "menu_id": 3,
            "parent_id": null,
            "sort": 53,
            "label": "SIZE CHART",
            "url": {
              "type": "page",
              "value": 15
            },
            "status": 0,
            "created_on": "2016-11-08 14:07:02",
            "hide_main_menu": false
          }
];

export const footer3: ICategory[] = [
      {
        "id": 54,
        "menu_id": 4,
        "parent_id": null,
        "sort": 54,
        "label": "LOGIN",
        "url": {
          "type": "static",
          "value": "\/login"
        },
        "status": 0,
        "created_on": "2016-11-08 14:07:20",
        "hide_main_menu": false
      },
      {
        "id": 55,
        "menu_id": 4,
        "parent_id": null,
        "sort": 55,
        "label": "SHOPPING BASKET",
        "url": {
          "type": null,
          "value": ""
        },
        "status": 0,
        "created_on": "2016-11-08 14:07:26",
        "hide_main_menu": false
      },
      {
        "id": 56,
        "menu_id": 4,
        "parent_id": null,
        "sort": 56,
        "label": "WISH LIST",
        "url": {
          "type": "static",
          "value": "\/wishlist"
        },
        "status": 0,
        "created_on": "2016-11-08 14:07:31",
        "hide_main_menu": false
      },
      {
        "id": 67,
        "menu_id": 4,
        "parent_id": null,
        "sort": 67,
        "label": "CONTACT US",
        "url": {
          "type": "page",
          "value": 14
        },
        "status": 0,
        "created_on": "2017-02-10 15:59:07",
        "hide_main_menu": false
      }
];

export const footerMenu: ICategory[][] = [footer1, footer2, footer3];