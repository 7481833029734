import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SideBars } from '../../models/layout.constants';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material';
import { SearchBottomSheetComponent } from '../search-bottom-sheet/search-bottom-sheet.component';

@Component({
  selector: 'bared-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {
  
  @Input() searchPrefix = 'search';
  @Output() navigate = new EventEmitter<string[]>();
  @Output() openSidebar = new EventEmitter<SideBars>();
  config: MatBottomSheetConfig = {
    hasBackdrop: true,
    disableClose: false,
    panelClass: 'search-bottom-sheet'
  };
  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
  }
  public showCart() {
    this.openSidebar.emit(SideBars.ShoppingCart);
  }
  public showSearch() {
    const ref = this._bottomSheet.open(SearchBottomSheetComponent, this.config);
    ref.afterDismissed().subscribe( result => {
      this.performSearch(result);
    })
  }
  public performSearch(query: string ) {
    this.navigate.emit([this.searchPrefix, query])
  }
  public showMenu() {
    this.openSidebar.emit(SideBars.MobileMenu);
  }
}
